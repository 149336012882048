import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { createOrder } from "src/api";
import { renderCurrency, renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput, CustomTextInputWithoutErrorMessage } from "src/components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { useOrderContext } from "src/pages/Dashboard/Orders/context";
import * as Yup from "yup";

const Payment = ({ setIsPaymentOpen }) => {
	const { user } = useProtectedRoutesContext();
	const {
		paidAmount,
		setPaidAmount,
		setIsPaid,
		isPaid,
		orderItems,
		customerID,
		isExpressDelivery,
		notes,
		totalPrice,
		setDiscount,
		discount,
		resetContext,
		revenue,
		paymentType,
		deliveryOption,
		dateTimeOut,
		discountValue,
		deliveryAmount,
	} = useOrderContext();

	const [error, setError] = useState("");

	const { mutate, isLoading } = useMutation(createOrder, {
		onSuccess: ({ message, data }) =>
			renderSuccessMessage(message).then(() => {
				setIsPaymentOpen(false);
				window.open(`/receipt/${data?.serial_number}`, "_blank");
				resetContext();
			}),
	});

	const normalOrderSchema = Yup.object().shape({
		customer_id: Yup.string().required("Please select a customer for the order"),
		store_id: Yup.string().required("Store ID is required for the order"),
		staffId: Yup.string().required("Staff ID is required for the order"),
		total_amount: Yup.number().min(0),
		note: Yup.string(),
		discount: Yup.number().min(0, "Minimum discount amount is 0"),
		isExpress: Yup.boolean(),
		is_paid: Yup.boolean(),
		order_items: Yup.array().min(1, "There must be at least one item in the order"),
		paymentType: Yup.string().required("A method of payment is required"),
		deliveryId: Yup.string().required("Please select a delivery option"),
		dateTimeOut: Yup.string().required("A completion date is required"),
	});

	return (
		<div className="flex flex-col gap-[10px]">
			<div className="flex items-center gap-2 bg-lightGray rounded-md px-[12px] py-[10px] mb-[10px] flex-1">
				<input type="checkbox" checked={isPaid} id="Made Full Payment" onChange={(event) => setIsPaid(event.target.checked)} />
				<label htmlFor="Made Full Payment" className="text-darkText text-[1.4rem] font-medium">
					Made Full Payment
				</label>
			</div>
			<CustomTextInputWithoutErrorMessage
				label="Paid Amount"
				placeholder="Enter Amount Paid"
				type="number"
				onChange={(event) => setPaidAmount(event.target.value.replace(/[^0-9.]/g, ""))}
				disabled={isPaid}
			/>
			<CustomTextInput
				label="Discount (%)"
				placeholder="Enter Discount"
				type="number"
				max={100}
				min={0}
				value={discount}
				onChange={(event) => setDiscount(parseFloat(event.target.value) || 0)}
			/>
			<div className="border borderlightGray px-[16px] py-[8px] bg-[#FBFBFB] rounded-md mt-[40px]">
				<div className="flex items-center justify-between mb-2">
					<p className="text-[1.3rem] text-darkText font-medium">Total Items</p>
					<p className="text-[1.3rem] text-darkText font-medium">
						{orderItems?.reduce((count, currentItem) => count + parseInt(currentItem?.pieces ?? 1), 0)}
					</p>
				</div>
				<div className="flex items-center justify-between mb-2">
					<p className="text-[1.3rem] text-darkText font-medium">Discount</p>
					<p className="text-[1.3rem] text-darkText font-medium">{discount || 0} %</p>
				</div>
				<div className="flex items-center justify-between mb-2">
					<p className="text-[1.3rem] text-darkText font-medium">Delivery</p>
					<p className="text-[1.3rem] text-darkText font-medium">{renderCurrency(deliveryAmount)}</p>
				</div>
				<div className="flex items-center justify-between mb-2">
					<p className="text-[1.3rem] text-darkText font-medium">Total Price</p>
					<p className="text-[1.3rem] text-darkText font-medium">
						{renderCurrency(totalPrice * ((100 - discount) / 100) * discountValue + deliveryAmount)}
					</p>
				</div>
			</div>
			<p className="text-[1rem] text-red-500">{error}</p>
			<button
				className="mt-4 font-semibold btn-primary"
				disabled={isLoading}
				onClick={async () => {
					const discountAmount = discount ? parseFloat(totalPrice * (discount / 100)) : 0;
					const bill = parseFloat(totalPrice - discountAmount);

					const groupedItems = Object.groupBy(orderItems, ({ name, pieces }) => name.concat("-", parseInt(pieces)));

					const summary = Object.entries(groupedItems)
						.map(([key, value]) => {
							const [name, pieces] = key.split("-");
							const items = value.map((item) => {
								const extra_info = JSON.parse(item?.extra_info);
								return `${item.brand}/${extra_info.color}/${extra_info?.pattern}/${extra_info?.material}`;
							});
							return `${name} (${value?.length} x ${pieces}) [${items?.join(", ")}]`;
						})
						.join(", ");

					const data = {
						customer_id: customerID,
						store_id: user?.location_id,
						staffId: user?.id,
						total_amount: parseFloat(totalPrice),
						note: notes,
						discount: discountAmount,
						isExpress: isExpressDelivery,
						is_paid: isPaid,
						paidAmount: isPaid ? bill * discountValue + deliveryAmount : paidAmount,
						order_items: orderItems?.map(({ chargeObj, ...item }) => item),
						extra_info: "",
						revenue: parseFloat(revenue),
						paymentType,
						deliveryId: deliveryOption,
						dateTimeOut,
						discount_percentage: discount,
						extra_discount_percentage: discountValue,
						extra_discount_value: bill * discountValue,
						summary,
					};
					try {
						setError("");
						const parsedUser = await normalOrderSchema.validate(data);
						mutate(parsedUser);
					} catch (error) {
						setError(error.message);
					}
				}}
			>
				Confirm
			</button>
		</div>
	);
};

export default Payment;
