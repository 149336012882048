import { Suspense, useState } from "react";
import Products from "./Products";
import Department from "./Department";
import Locations from "./Locations";
import Routes from "./Routes";
import DeliveryOption from "./DeliveryOption";
import ItemSettings from "./ItemSettings";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Loader } from "src/components";
import { useSearchParams } from "react-router-dom";
import SetCleaningDuration from "./SetCleaningDuration";
import ShortCode from "./ShortCode";
import ItemPattern from "./ItemPattern";
import Discounts from "./Discounts";
import Charges from "./Charges";

const Settings = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [openTabModal, setOpenTabModal] = useState(false);

	const closeModal = () => {
		setOpenTabModal(false);
	};

	return (
		<>
			<div className="bg-white border-b border-b-lightGray flex items-center justify-between py-[1.45rem]">
				<div className="inline-flex items-center gap-5">
					<div className="text-justify text-black font-semibold text-[1.9rem]">Settings</div>
				</div>
				<div>
					<button className="btn-primary" onClick={() => setOpenTabModal(true)}>
						Add New
					</button>
				</div>
			</div>

			<div>
				<Tabs
					className="mt-20 modal-tabs"
					defaultIndex={parseInt(searchParams.get("tab")) || 0}
					onSelect={(index) => setSearchParams({ tab: index })}
				>
					<TabList className="mb-20 border-b">
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Products
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Departments
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Locations
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Routes
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Delivery Options
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Item Settings
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Set Cleaning Duration
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Short Code
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Pattern
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Discounts
						</Tab>
						<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
							Charges
						</Tab>
					</TabList>

					<Suspense fallback={<Loader spin />}>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<Products openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<Department openModal={openTabModal} setOpenModal={setOpenTabModal} closeModal={closeModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<Locations openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<Routes openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<DeliveryOption openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<ItemSettings />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<SetCleaningDuration />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<ShortCode openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<ItemPattern openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<Discounts openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
						<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
							<Charges openModal={openTabModal} setOpenModal={setOpenTabModal} />
						</TabPanel>
					</Suspense>
				</Tabs>
			</div>
		</>
	);
};

export default Settings;
