import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderById } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { SearchIcon } from "src/assets/svg";
import { CustomDataTable, Modal } from "src/components";
import { default as TrackItemModal } from "src/modals/TrackItem";

const TrackOrder = () => {
	const { orderId } = useParams();

	const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);

	const { data } = useQuery({
		queryKey: ["order", orderId],
		queryFn: () => getOrderById(orderId),
		suspense: true,
	});

	const columns = [
		{ name: "ID", selector: (row) => row?.item?.tagId, width: "120px", compact: true },
		{
			name: "Item",
			cell: (row) => {
				const extra_info = JSON.parse(row?.item?.extra_info);
				return (
					<div>
						{[
							extra_info?.color,
							row?.item?.brand,
							row?.item?.name,
							row?.item?.description || "",
							`(${row?.item?.product_option?.option_name})`,
							`| ${extra_info?.style}`,
						].join(" ")}
					</div>
				);
			},
			style: { textTransform: "capitalize" },
			compact: true,
			grow: 1.5,
		},
		{
			name: "View",
			cell: (row) => (
				<button
					onClick={() => {
						setSelectedItem(row);
						setIsTrackModalOpen(true);
					}}
				>
					<SearchIcon className="cursor-pointer stroke-primary text-primary" />
				</button>
			),
			compact: true,
		},
	];

	const order = data?.data?.at(0);
	const items = order?.transactions;

	return (
		<div className="mt-14">
			<div className="max-w-[1100px] bg-white px-10 py-10">
				<h1 className="text-black text-[2.4rem] font-bold">Track Order ({order?.serial_number})</h1>
				<h2 className="text-[1.8rem] text-black font-semibold mb-[1rem]">Customer: {order?.customer?.full_name}</h2>
				<div className="grid grid-cols-3 mb-[3.5rem]">
					<div>
						<p className="text-[#5E6C85] text-[1.2rem] font-medium">CREATED:</p>
						<p className="text-[1.6rem] text-black font-medium">{format(parseISO(order?.created_at), "dd/MM/yyyy")}, Station 1</p>
					</div>
					<div className="grid">
						<div className="w-fit justify-self-center">
							<p className=" text-[#5E6C85] text-[1.2rem] font-medium">ORDER BILL:</p>
							<p className="text-[1.6rem] text-black font-medium">{renderCurrency(order?.bill)}</p>
						</div>
					</div>
					<div className="grid">
						<p className="justify-self-end text-[#5E6C85] text-[1.2rem] font-medium w-fit">AMOUNT PAID:</p>
						<p className="justify-self-end text-[1.6rem] text-black font-medium w-fit">{renderCurrency(order?.paidAmount)}</p>
					</div>
				</div>
				<CustomDataTable columns={columns} data={items ?? []} />
			</div>
			<Modal isOpen={isTrackModalOpen} setIsOpen={setIsTrackModalOpen} title="Track Item">
				<TrackItemModal selectedItem={selectedItem} />
			</Modal>
		</div>
	);
};

export default TrackOrder;
