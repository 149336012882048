import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
	addSingleColorOrBrand,
	editOrder,
	editOrderItem,
	findCustomer,
	getAllDamages,
	getAllPattern,
	getDeliveryOptions,
	getDiscountType,
	getItemSettings,
	getOrder,
	getOrderInfo,
} from "src/api";
import DatePicker from "react-datepicker";
import debounce from "lodash/debounce";
import { Formik } from "formik";
import { renderCurrency, renderSuccessMessage } from "src/assets/functions";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { format, parseISO } from "date-fns";
import { CustomDataTable, CustomSelectInput, CustomTextArea, CustomTextInput, Modal } from "src/components";
import CustomMultiSelect from "src/components/CustomMultiSelect";
import { components } from "react-select";
import toHex from "colornames";
import { EditIcon } from "src/assets/svg";
import * as Yup from "yup";

const columns = [
	{
		name: "Item",
		selector: (row) => {
			const extra_info = JSON.parse(row?.extra_info);
			return "".concat(extra_info?.color, " ", row?.brand, " ", row?.name, " ", row?.description || "");
		},
		style: { textTransform: "capitalize" },
		compact: true,
	},
	{
		name: "Notes",
		cell: (row) => <div>{row?.notes}</div>,
		wrap: true,
		style: { paddingBlock: 10 },
		compact: true,
		maxWidth: "350px",
	},
	{
		name: "Action",
		cell: (row) => <Action row={row} />,
		style: { paddingBlock: 10 },
		maxWidth: "80px",
	},
];

const EditOrder = () => {
	const { user } = useProtectedRoutesContext();
	const { id } = useParams();

	const filterOptions = debounce(async (inputValue) => {
		const data = await findCustomer(inputValue);

		const result = data.data.map((person) => ({
			value: person.id,
			label: person.full_name,
			default_payment: person?.default_payment,
		}));

		return new Promise((resolve) => {
			resolve(result);
		});
	}, 200);
	const { data: deliveryOptions } = useQuery({
		queryKey: ["deleivery-options"],
		queryFn: getDeliveryOptions,
		suspense: true,
	});
	const { data: rawOrder, refetch } = useQuery({
		queryKey: ["order", id],
		queryFn: () => getOrder(id),
		suspense: true,
	});

	const order = rawOrder?.data?.at(0);

	const { data: rawItems } = useQuery({
		queryKey: ["order_info", order?.uuid],
		queryFn: () => getOrderInfo({ uuid: order?.uuid, status: "processing" }),
		enabled: !!order,
		suspense: true,
	});
	const { data: disocuntTypes } = useQuery({
		queryKey: ["discount-type"],
		queryFn: getDiscountType,
		suspense: true,
	});

	const items = rawItems?.data?.data;

	const initialValues = {
		id: order?.id,
		customer_id: order?.customer_id,
		store_id: user?.location_id,
		staffId: user?.id,
		note: order?.note ?? "",
		isExpress: !!parseInt(order?.isExpress) ?? false,
		is_paid: !!parseInt(order?.is_paid),
		paidAmount: order?.paidAmount,
		paymentType: order?.paymentType ?? "",
		delivery_id: order?.delivery_id,
		dateTimeOut: order?.dateTimeOut,
		extra_discount_percentage: order?.extra_discount_percentage,
		discount_percentage: order?.discount_percentage,
	};

	const { mutate, isLoading } = useMutation(editOrder, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
	});

	return (
		<div>
			<div className="flex items-center justify-between my-[2rem]">
				<h1 className="text-black text-[2rem] font-bold">Edit Order</h1>
			</div>
			<Formik
				initialValues={initialValues}
				onSubmit={({ paidAmount, is_paid, ...values }) => {
					const data = { is_paid, paidAmount: is_paid ? order?.bill : paidAmount, ...values };
					mutate(data);
				}}
			>
				{({ values, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-5">
							<div className="p-5 bg-white ">
								<div className="flex items-center gap-4 mb-4">
									<AsyncSelect
										isClearable
										placeholder="Enter customer name or ID"
										loadOptions={filterOptions}
										styles={{
											container: (baseStyles) => ({
												...baseStyles,
												flex: 1,
											}),
											control: (baseStyles) => ({
												...baseStyles,
												border: "2px solid var(--light-gray)",
											}),
											placeholder: (baseStyles) => ({
												...baseStyles,
												fontSize: "1.4rem",
												fontWeight: 500,
											}),
											valueContainer: (baseStyles) => ({
												...baseStyles,
												padding: "4px 8px",
											}),
											singleValue: (baseStyles) => ({
												...baseStyles,
												fontSize: "1.4rem",
												fontWeight: 500,
											}),
										}}
										components={{
											DropdownIndicator: null,
										}}
										onChange={(value) => {
											setFieldValue("customer_id", value?.value);
											setFieldValue("paymentType", value?.default_payment ?? "cash");
										}}
										defaultOptions={filterOptions(order?.customer?.full_name)}
										defaultValue={{ label: order?.customer?.full_name, value: order?.customer_id }}
									/>
								</div>

								{/* delivery options */}
								<div className="grid grid-cols-2 gap-4 mb-4">
									<select
										className="border border-lightGray rounded-md p-[10px] text-[1.3rem] font-medium placeholder:text-[1.3rem] text-lightText flex-1"
										name="delivery_id"
										value={values.delivery_id}
										onChange={handleChange}
									>
										<option value="" disabled>
											Delivery Fees
										</option>
										{deliveryOptions?.data?.map((option) => (
											<option value={`${option.id}`} key={option.id}>
												{option.name} ({renderCurrency(option?.amount)})
											</option>
										))}
									</select>
									<select
										className="border border-lightGray rounded-md p-[10px] text-[1.3rem] font-medium placeholder:text-[1.3rem] text-lightText flex-1"
										name="paymentType"
										value={values.paymentType}
										onChange={handleChange}
									>
										<option value="" disabled>
											Payment Method
										</option>
										<option value="cash">Cash</option>
										<option value="card">Card</option>
										<option value="cheque">Cheque</option>
										<option value="transfer">Transfer</option>
										<option value="wallet">Wallet</option>
									</select>
									<div className="flex items-center gap-2 bg-lightGray rounded-md px-[12px] py-[10px] flex-1">
										<input
											type="checkbox"
											checked={values.isExpress}
											id="Express Delivery"
											onChange={(event) => setFieldValue("isExpress", event.target.checked)}
										/>
										<label htmlFor="Express Delivery" className="text-darkText text-[1.2rem] font-medium">
											Express Delivery
										</label>
									</div>
									<DatePicker
										dateFormat="dd MMMM yyyy HH:mm"
										showYearDropdown
										showMonthDropdown
										dropdownMode="select"
										name="birthday"
										className="p-[7px] border border-lightGray rounded-md w-full text-[1.3rem] text-lightText font-medium"
										selected={values.dateTimeOut ? parseISO(values.dateTimeOut) : new Date()}
										minDate={new Date()}
										showTimeSelect
										timeFormat="HH:mm"
										timeIntervals={15}
										timeCaption="time"
										onChange={(date) => setFieldValue("dateTimeOut", format(date, "yyyy-MM-dd HH:mm:ss"))}
										disabled={values.isExpress}
									/>
									<select
										className="border border-lightGray rounded-md p-[10px] text-[1.3rem] font-medium placeholder:text-[1.3rem] text-lightText flex-1 col-span-2"
										value={values.extra_discount_percentage}
										name="extra_discount_percentage"
										onChange={handleChange}
									>
										<option value="1">No Discount</option>
										{disocuntTypes?.data?.map((option) => (
											<option value={`${option.percentage}`} key={option.id}>
												{option.type}
											</option>
										))}
									</select>
									<textarea
										rows="3"
										name="note"
										className="border border-lightGray bg-[#FBFBFB] mt-4 p-[8px] text-[1.3rem] text-lightText col-span-2"
										placeholder="Order Notes"
										value={values.note}
										onChange={handleChange}
									>
										Notes
									</textarea>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-5 p-5 bg-white h-fit">
								<div className="flex items-center gap-2 bg-lightGray rounded-md px-[12px] py-[10px] mb-[10px] flex-1 max-h-[80px]">
									<input
										type="checkbox"
										checked={values.is_paid}
										id="Made Full Payment"
										name="is_paid"
										onChange={(event) => setFieldValue("is_paid", event.target.checked)}
									/>
									<label htmlFor="Made Full Payment" className="text-darkText text-[1.4rem] font-medium">
										Made Full Payment
									</label>
								</div>
								<CustomTextInput
									label="Paid Amount"
									placeholder="Enter Amount Paid"
									type="number"
									name="paidAmount"
									value={values.paidAmount}
									onChange={handleChange}
									disabled={values.is_paid}
								/>
								{user?.role === "admin" && (
									<CustomTextInput
										label="Discount Applied"
										placeholder="Enter Discount"
										type="number"
										name="discount_percentage"
										value={values.discount_percentage}
										onChange={handleChange}
									/>
								)}
								<button className="mt-auto btn-primary w-fit h-fit" disabled={isLoading} type="submit">
									Edit Order
								</button>
							</div>
						</div>
					</form>
				)}
			</Formik>
			<div className="max-w-[1000px] mt-[3rem]">
				<CustomDataTable columns={columns} data={items} />
			</div>
		</div>
	);
};

const Action = ({ row }) => {
	const [modalOpen, setModalOpen] = useState(false);
	return (
		<>
			<div>
				<EditIcon className="cursor-pointer stroke-primary text-primary" onClick={() => setModalOpen(true)} />
			</div>
			<Modal isOpen={modalOpen} setIsOpen={setModalOpen} title="Edit Order Garment">
				<EditModal garment={row} setIsOpen={setModalOpen} />
			</Modal>
		</>
	);
};

const EditModal = ({ garment, setIsOpen }) => {
	const queryClient = useQueryClient();

	const { data, refetch } = useQuery({
		queryKey: ["item-Settings"],
		queryFn: () => getItemSettings(),
		suspense: true,
	});

	const { data: data1 } = useQuery({
		queryKey: ["getAllDamages"],
		queryFn: () => getAllDamages(),
		suspense: true,
	});

	const { data: getpattern } = useQuery({
		queryKey: ["getAllPattern"],
		queryFn: () => getAllPattern(),
		suspense: true,
	});

	const options = {
		stains: data.data[0]?.stains
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		styles: data.data[0]?.styles
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		patterns: data.data[0]?.patterns
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		materials: data.data[0]?.materials
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		brand: Array.from(new Set(data.data[0]?.brand?.split(",")))
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		color: Array.from(new Set(data.data[0]?.color?.split(",")))
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
	};

	const options1 = {
		damage_info: data1?.data?.map((data) => ({ label: data.short_code, value: data.short_code })),
	};

	const options2 = {
		pattern: getpattern?.data?.map((data) => ({ label: data.full_version, value: data.full_version, image: data.image })),
	};

	const { mutate } = useMutation(addSingleColorOrBrand, {
		onSuccess: ({ message }) => {
			refetch();
			renderSuccessMessage(message);
		},
	});
	const { mutate: editMutate, isLoading } = useMutation(editOrderItem, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				queryClient.invalidateQueries(
					{
						queryKey: ["order_info"],
						refetchType: "all",
					},
					{ throwOnError: true }
				);
				setIsOpen(false);
			}),
	});

	const PatternOption = (props) => {
		return (
			<div className="grid grid-cols-2">
				<components.Option {...props} />
				<div className="bg-repeat" style={{ backgroundImage: `url(${props.data.image})` }} onClick={props?.innerProps?.onClick} />
			</div>
		);
	};

	const ColorOption = (props) => {
		return (
			<div className="grid grid-cols-2">
				<components.Option {...props} />
				<div className="bg-repeat" style={{ backgroundColor: toHex(props.value.toLowerCase()) }} onClick={props?.innerProps?.onClick} />
			</div>
		);
	};

	const extra_info = JSON.parse(garment?.extra_info);

	const initialValues = {
		itemId: garment?.id,
		brand: { label: garment?.brand, value: garment?.brand },
		color: extra_info?.color?.split(",")?.map((color) => ({ label: color, value: color })),
		pattern: { label: extra_info?.pattern, value: extra_info?.pattern },
		material: extra_info?.material,
		style: extra_info?.style,
		stains: extra_info?.stains,
		damage: extra_info?.damage ? extra_info?.damage?.split(",")?.map((damage) => ({ label: damage, value: damage })) : null,
		notes: garment?.notes ?? "",
	};
	const validationSchema = Yup.object().shape({
		brand: Yup.object()
			.shape({
				label: Yup.string().required("Please provide an brand for this item"),
				value: Yup.string().required("Please provide an brand for this item"),
			})
			.typeError("Please provide an brand for this item"),
		color: Yup.array().min(1, "Please provide a color for this item"),
		material: Yup.string().required("Please provide the material for this item"),
		pattern: Yup.object().shape({
			label: Yup.string().required("Please provide the pattern for this item"),
			value: Yup.string().required("Please provide the pattern for this item"),
		}),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={({ brand, itemId, notes, ...values }) => {
				const data = {
					itemId,
					brand: brand?.value,
					extra: JSON.stringify({
						color: values?.color?.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1),
						pattern: values?.pattern?.value,
						material: values?.material,
						style: values?.style,
						stains: values?.stains,
						damage: values?.damage?.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1),
					}),
					notes,
				};
				editMutate(data);
			}}
		>
			{({ values, setFieldValue, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<div className="grid grid-cols-2 mt-3 gap-x-6 gap-y-6">
						<CustomMultiSelect
							defaultValue={values.brand}
							label="Brand"
							name="brand"
							placeholder="Enter Brand"
							options={options?.brand}
							onChange={(value) => {
								setFieldValue("brand", value);
							}}
							isClearable
							onCreateOption={(value) => {
								mutate({ brand: value });
								setFieldValue("brand", [{ label: value, value }]);
							}}
						/>
						<CustomMultiSelect
							defaultValue={values.color}
							label="Color"
							name="color"
							isMulti
							placeholder="Enter Color"
							options={options?.color}
							onChange={(value) => {
								setFieldValue("color", value);
							}}
							isClearable
							onCreateOption={(value) => {
								mutate({ color: value });
								setFieldValue("color", [{ label: value, value }]);
							}}
							components={{ Option: ColorOption }}
						/>
						<CustomMultiSelect
							defaultValue={values.pattern}
							label="Pattern"
							name="pattern"
							placeholder="Select Pattern"
							options={options2?.pattern}
							onChange={(value) => {
								setFieldValue("pattern", value);
							}}
							components={{ Option: PatternOption }}
							closeMenuOnSelect
						/>
						<CustomSelectInput
							defaultValue={values.material}
							label="Material"
							name="material"
							placeholder="Enter Cloth Material"
							options={options?.materials}
							onChange={handleChange}
						/>
						<CustomSelectInput
							defaultValue={values.style}
							label="Preference"
							name="style"
							placeholder="Enter Cloth Preference"
							options={options?.styles}
							onChange={handleChange}
						/>
						<CustomSelectInput
							defaultValue={values.stains}
							label="Stains"
							name="stains"
							placeholder="Enter Cloth Stains"
							options={options?.stains}
							onChange={handleChange}
						/>
						<CustomMultiSelect
							defaultValue={values.damage}
							label="Info"
							name="damage"
							isMulti
							placeholder="Select Info"
							options={options1?.damage_info}
							onChange={(value) => {
								setFieldValue("damage", value);
							}}
							isClearable
						/>
						<CustomTextArea
							defaultValue={values.notes}
							label="Notes"
							name="notes"
							placeholder="Enter Any Notes"
							onChange={(event) => setFieldValue("notes", event.target.value)}
							containerClassName={"!col-span-1"}
							rows={"2"}
						/>
					</div>
					<div className="flex gap-4 mt-[35px]">
						<button type="submit" className="ml-auto small-btn-primary" disabled={isLoading}>
							Edit
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default EditOrder;
