import React, { useState } from "react";
import CustomDataTable from "../CustomDataTable";
import { format, parseISO } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getInvoice } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { CustomPopup } from "..";
import { DownIcon } from "src/assets/svg";

const Invoice = () => {
	const [text, setText] = useState("all");
	const [view, setView] = useState("All");

	const columns = [
		{
			name: "Date Created",
			selector: (row) => row?.created_at && format(parseISO(row?.created_at), "dd MMM yyyy"),
			compact: true,
		},
		{
			name: "Price",
			selector: (row) => renderCurrency(row?.total),
			compact: true,
		},

		{
			name: "Paid",
			selector: (row) => (!!parseInt(row?.isPaid) ? "Yes" : "No"),
			compact: true,
		},
	];

	const { data } = useQuery({
		queryKey: ["invoices", text],
		queryFn: () => getInvoice(text),
		suspense: true,
	});

	const invoices = data?.data?.orderInvoice;

	return (
		<div>
			<div className="flex items-center justify-between mb-8">
				<CustomPopup
					trigger={
						<button
							type="button"
							className="capitalize flex min-w-[90px] items-center justify-center px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText ml-auto"
						>
							{view || "All"} <DownIcon />
						</button>
					}
					arrow={false}
					closeOnDocumentClick
					position={["bottom right"]}
					width={"140px"}
					nested
					childrenArray={[
						{
							text: <div className="flex items-center gap-3">All</div>,
							onClick: () => {
								setText("all");
								setView("All");
							},
						},
						{
							text: <div className="flex items-center gap-3">Paid</div>,
							onClick: () => {
								setText(1);
								setView("Paid");
							},
						},
						{
							text: <div className="flex items-center gap-3">Unpaid</div>,
							onClick: () => {
								setText(0);
								setView("Unpaid");
							},
						},
					]}
				/>
			</div>
			<CustomDataTable
				columns={columns}
				data={invoices}
				paginationServer={false}
				paginationDefaultPage={1}
				paginationPerPage={50}
				onChangePage={() => {}}
				onChangeRowsPerPage={() => {}}
			/>
		</div>
	);
};

export default Invoice;
