import { useQuery } from "@tanstack/react-query";
import { addDays, format } from "date-fns";
import { createContext, useContext, useEffect, useState } from "react";
import { getOrderOption } from "src/api";

const OrderContext = createContext({
	orderItems: [],
	setOrderItems: () => {},
	customerID: "",
	setCustomerID: () => {},
	isExpressDelivery: false,
	setIsExpressDelivery: () => {},
	notes: "",
	setNotes: () => {},
	resetContext: () => {},
	resetQuickDrop: () => {},
	isPaid: false,
	setIsPaid: () => {},
	paidAmount: 0,
	setPaidAmount: () => {},
	discount: 0,
	setDiscount: () => {},
	items_count: 0,
	setItems_count: () => {},
	deliveryOption: "2",
	setDeliveryOption: () => {},
	paymentType: "cash",
	setPaymentMethod: () => {},
	orderID: "",
	setOrderID: () => {},
	dateTimeOut: "",
	setDateTimeOut: () => {},
	discountValue: "1",
	setDiscountValue: () => {},
	deliveryAmount: 0,
	setDeliveryAmount: () => {},
});

export default function OrderContextProvider({ children }) {
	const [orderItems, setOrderItems] = useState([]);
	const [customerID, setCustomerID] = useState("");
	const [isExpressDelivery, setIsExpressDelivery] = useState(false);
	const [notes, setNotes] = useState("");
	const [isPaid, setIsPaid] = useState(false);
	const [paidAmount, setPaidAmount] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [items_count, setItems_count] = useState(0);
	const [orderID, setOrderID] = useState("");
	// ? last 2 have default values (told they will never change)
	const [paymentType, setPaymentMethod] = useState("cash");
	const [deliveryOption, setDeliveryOption] = useState("2");
	const [discountValue, setDiscountValue] = useState("1");
	const [deliveryAmount, setDeliveryAmount] = useState(0);

	const { data: getduration } = useQuery({
		queryKey: ["getOrderOption"],
		queryFn: getOrderOption,
		suspense: true,
	});

	const [dateTimeOut, setDateTimeOut] = useState(
		format(
			addDays(new Date(), !!isExpressDelivery ? 1 : getduration?.data?.number_of_days), // Initial calculation
			"yyyy-MM-dd"
		)
	);

	useEffect(() => {
		// Recalculate dateTimeOut when isExpressDelivery changes
		setDateTimeOut(format(addDays(new Date(), !!isExpressDelivery ? 1 : getduration?.data?.number_of_days), "yyyy-MM-dd"));
	}, [isExpressDelivery, getduration?.data?.number_of_days]);

	const resetContext = () => {
		setOrderItems([]);
		setIsExpressDelivery(false);
		setNotes("");
		setIsPaid(false);
		setPaidAmount(0);
		setDeliveryOption("2");
		setItems_count(0);
		setDateTimeOut(format(new Date(), "yyyy-MM-dd"));
		setDiscountValue("1");
		setDeliveryAmount(0);
	};

	const resetQuickDrop = () => {
		setIsExpressDelivery(false);
		setDeliveryOption("2");
		setItems_count(0);
		setDateTimeOut(format(new Date(), "yyyy-MM-dd"));
		setDiscountValue("1");
		setDeliveryAmount(0);
	};

	return (
		<OrderContext.Provider
			value={{
				orderItems,
				setOrderItems,
				customerID,
				setCustomerID,
				isExpressDelivery,
				setIsExpressDelivery,
				notes,
				setNotes,
				totalPrice: isExpressDelivery
					? orderItems.reduce(
							(prevValue, currentItem) => prevValue + parseInt(currentItem.expressPrice) + parseInt(currentItem?.chargeObj?.value || 0),
							0
					  )
					: orderItems.reduce(
							(prevValue, currentItem) => prevValue + parseInt(currentItem.price) + parseInt(currentItem?.chargeObj?.value || 0),
							0
					  ),
				isPaid,
				setIsPaid,
				paidAmount,
				setPaidAmount,
				discount,
				setDiscount,
				deliveryOption,
				setDeliveryOption,
				revenue: isExpressDelivery
					? orderItems.reduce(
							(prevValue, currentItem) =>
								prevValue +
								(parseInt(currentItem.expressPrice) + parseInt(currentItem?.chargeObj?.value) - parseInt(currentItem.cost_price)),
							0
					  )
					: orderItems.reduce(
							(prevValue, currentItem) =>
								prevValue +
								(parseInt(currentItem.price) + parseInt(currentItem?.chargeObj?.value) - parseInt(currentItem.cost_price)),
							0
					  ),
				resetContext,
				items_count,
				setItems_count,
				resetQuickDrop,
				paymentType,
				setPaymentMethod,
				orderID,
				setOrderID,
				dateTimeOut,
				setDateTimeOut,
				discountValue,
				setDiscountValue,
				deliveryAmount,
				setDeliveryAmount,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
}

export const useOrderContext = () => useContext(OrderContext);
