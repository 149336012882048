import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { switchUser } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import * as Yup from "yup";

const SwitchUser = ({ setIsModalOpen }) => {
	const { setUser, setToken } = useProtectedRoutesContext();

	const initialValues = {
		staffCode: "",
	};
	const validationSchema = Yup.object().shape({
		staffCode: Yup.string().required("Please provide your staff code"),
	});

	const { mutate, isLoading } = useMutation(switchUser, {
		onSuccess: ({ message, data, token }) => {
			setUser(data);
			setToken(token);
			renderSuccessMessage(message).then(() => setIsModalOpen(false));
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});
	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<CustomTextInput
						label="Code"
						placeholder="Enter Code"
						onChange={handleChange}
						value={values.staffCode}
						name="staffCode"
						type="password"
					/>
					<button className="w-full mt-6 btn-primary" disabled={isLoading}>
						Switch
					</button>
				</form>
			)}
		</Formik>
	);
};

export default SwitchUser;
