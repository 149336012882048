import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import AsyncSelect from "react-select/async";
import { findCustomer, mergeCustomers } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import * as Yup from "yup";

const filterOptions = async (inputValue) => {
	if (!inputValue) return;

	const data = (await findCustomer(inputValue)).data.map((person) => ({
		value: person.uuid,
		label: person.full_name,
	}));
	return data;
};

const MergeCustomers = ({ setIsOpen, refetch }) => {
	const initialValues = {
		toCustomer: "",
		fromCustomer: "",
	};
	const validationSchema = Yup.object().shape({
		fromCustomer: Yup.string().required("Please provide a customer to merge from"),
		toCustomer: Yup.string().required("Please provide a customer to merge to"),
	});

	const { mutate, isLoading } = useMutation(mergeCustomers, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				setIsOpen();
				refetch();
			}),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ setFieldValue, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-4">
					<h2 className="text-primary text-[1.4rem] font-bold">From</h2>
					<AsyncSelect
						isClearable
						placeholder="From..."
						loadOptions={filterOptions}
						cacheOptions
						defaultOptions
						styles={{
							container: (baseStyles) => ({
								...baseStyles,
								flex: 1,
							}),
							control: (baseStyles) => ({
								...baseStyles,
								border: "2px solid var(--light-gray)",
							}),
							placeholder: (baseStyles) => ({
								...baseStyles,
								fontSize: "1.4rem",
								fontWeight: 500,
							}),
							valueContainer: (baseStyles) => ({
								...baseStyles,
								padding: "4px 8px",
							}),
							singleValue: (baseStyles) => ({
								...baseStyles,
								fontSize: "1.4rem",
								fontWeight: 500,
							}),
						}}
						onChange={(value) => {
							setFieldValue("fromCustomer", value?.value);
						}}
					/>
					<h2 className="text-primary text-[1.4rem] font-bold">To</h2>
					<AsyncSelect
						isClearable
						placeholder="To..."
						loadOptions={filterOptions}
						cacheOptions
						defaultOptions
						styles={{
							container: (baseStyles) => ({
								...baseStyles,
								flex: 1,
							}),
							control: (baseStyles) => ({
								...baseStyles,
								border: "2px solid var(--light-gray)",
							}),
							placeholder: (baseStyles) => ({
								...baseStyles,
								fontSize: "1.4rem",
								fontWeight: 500,
							}),
							valueContainer: (baseStyles) => ({
								...baseStyles,
								padding: "4px 8px",
							}),
							singleValue: (baseStyles) => ({
								...baseStyles,
								fontSize: "1.4rem",
								fontWeight: 500,
							}),
						}}
						onChange={(value) => {
							setFieldValue("toCustomer", value?.value);
						}}
					/>
					<button type="submit" className="btn-primary" disabled={isLoading}>
						Merge Customer
					</button>
				</form>
			)}
		</Formik>
	);
};

export default MergeCustomers;
