import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { editStaff } from "src/api";
import { ALLOWED_PERMISSIONS } from "src/assets/constants";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { CustomCheckbox } from "src/components";

const StaffPermission = ({ selectedStaff, refetch, setIsPermissionsModalOpen }) => {
	const initialValues = {
		uuid: selectedStaff?.uuid,
		permissions: selectedStaff?.permissions || [],
	};

	const { mutate, isLoading } = useMutation(editStaff, {
		onSuccess: (res) => {
			renderSuccessMessage(res.message);
			refetch();
			setIsPermissionsModalOpen(false);
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<Formik initialValues={initialValues} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<p className="text-primary text-[1.6rem] font-medium mb-6">For: {selectedStaff?.fullName}</p>
					<div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-x-5 gap-y-10">
						{ALLOWED_PERMISSIONS?.map((permission, index) => (
							<CustomCheckbox
								key={index}
								label={permission.name}
								value={permission.permission}
								name="permissions"
								onChange={handleChange}
								defaultChecked={values.permissions.includes(permission.permission)}
							/>
						))}
					</div>
					<div className="flex gap-3 mt-10 ml-auto">
						<button
							type="button"
							className="btn"
							onClick={() => {
								setIsPermissionsModalOpen(false);
							}}
						>
							Cancel
						</button>
						<button type="submit" disabled={isLoading} className="btn-primary">
							Update Permissions
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default StaffPermission;
