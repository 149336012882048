import React from "react";

const CustomCheckbox = ({ label, ...rest }) => {
	return (
		<div className={`flex gap-2 items-center`}>
			<input
				type="checkbox"
				id={label}
				debounceTimeout={300}
				className={`border border-lightGray rounded-md p-[10px] text-[1.3rem] placeholder:text-[1.3rem] text-lightText ${rest.className}`}
				{...rest}
			/>
			<label htmlFor={label} className={`text-[1.4rem] text-lightText font-medium`}>
				{label}
			</label>
		</div>
	);
};

export default CustomCheckbox;
