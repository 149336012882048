import React from "react";
import "./tracker.css";
import { useQuery } from "@tanstack/react-query";
import { trackItemHistory } from "src/api";
import { format, parseISO } from "date-fns";

const generateHistory = (input) => {
	const data = {};

	input?.forEach((history) => {
		if (Object.keys(data).includes(history.name)) return;
		data[history.name] = {
			scan_in_out: history.scan_in_out,
			number_of_occurences: input.filter((obj) => obj.name === history.name).length,
			timeIn: input.find((obj) => obj.name === history.name && obj.stage === "scan-in"),
			timeOut: input.find((obj) => obj.name === history.name && obj.stage === "scan-out"),
		};
	});

	return data;
};

const isCompleted = ({ scan_in_out, timeOut }) => {
	if (!parseInt(scan_in_out)) {
		return true;
	} else {
		return !!timeOut;
	}
};

const TrackItem = ({ selectedItem }) => {
	const { data } = useQuery({
		queryKey: ["track-item-history", selectedItem?.id],
		queryFn: () => trackItemHistory(selectedItem?.id),
		suspense: true,
	});

	const history = generateHistory(data?.data);

	return (
		<div className="pt-[2rem]">
			<h2 className="text-black text-[1.8rem] font-bold mb-[2rem]">Item ID {selectedItem?.tagId ?? selectedItem?.item?.tagId} </h2>
			{!!Object.entries(history).length ? (
				<ul className="progress">
					{Object.entries(history)
						.reverse()
						.map(([key, value]) => {
							return (
								<li className={`progress__item ${isCompleted(value) ? "progress__item--completed" : ""}`} key={key}>
									<p className="capitalize progress__title">{key}</p>
									{!!value?.timeIn && (
										<div className="mb-5">
											<p className="progress__info">
												<span className="font-bold">Time In</span> : {format(parseISO(value?.timeIn?.created_at), "PPPp")}
											</p>
											<p className="progress__info">
												<span className="font-bold">Scanned In By</span> : {value?.timeIn?.fullName}
											</p>
											{value?.timeIn?.name === "inbound" && (
												<p className="progress__info">
													<span className="font-bold">Sent to</span> : {value?.timeIn?.sent_to_name}
												</p>
											)}
										</div>
									)}

									{!!value?.timeOut && (
										<div className="">
											<p className="progress__info">
												<span className="font-bold">Time Out</span> : {format(parseISO(value?.timeOut?.created_at), "PPPp")}
											</p>
											<p className="progress__info">
												<span className="font-bold">Scanned Out By</span> : {value?.timeOut?.fullName}
											</p>
											<p className="progress__info">
												<span className="font-bold">Sent to</span> : {value?.timeOut?.sent_to_name}
											</p>
										</div>
									)}
								</li>
							);
						})}
				</ul>
			) : (
				<div className="flex items-center justify-center">
					<h2>Process yet to begin</h2>
				</div>
			)}
		</div>
	);
};

export default TrackItem;
