import { ErrorMessage, Formik, useFormikContext } from "formik";
import React from "react";
import { renderCurrency } from "src/assets/functions";
import { QuantityInput } from "./QuantityInput";

const generateInitValues = (obj) => {
	var result = [];

	Object.entries(obj).forEach(([key, { quantity, price, product_id, product_option_id, expressPrice, cost_price, pieces }]) => {
		result.push(
			...Array(parseInt(quantity))
				.fill("")
				.map(() => ({
					name: key,
					product_id,
					product_option_id,
					expressPrice,
					cost_price,
					tag_id: "",
					brand: "",
					price,
					charge_id: null,
					pieces,
					description: "",
					notes: "",
					extra_info: {
						color: "",
						damage: "",
						pattern: "",
						notes: "",
						material: "",
						stains: "",
						style: "",
					},
				}))
		);
	});

	return result;
};

const OrderItemsSelector = ({ list, setStep, setIsModalOpen, setInitialValues }) => {
	const initialValues = {
		array: {},
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => {
				setStep(2);
				setInitialValues(generateInitValues(values.array));
			}}
			validate={(values) => {
				const errors = {};

				if (!Object.keys(values.array).length) {
					errors.array = "Must select option";
				}

				return errors;
			}}
		>
			{({ handleSubmit }) => (
				<form className="flex flex-col" onSubmit={handleSubmit}>
					<div className="grid gap-4 grid-cols-[repeat(auto-fill,minmax(110px,1fr))] mb-[20px]">
						{list.map((item, index) => (
							<Item item={item} key={index} />
						))}
					</div>
					<ErrorMessage name="array" component="div" className="text-[1rem] text-red-500" />
					<div className="flex gap-4 ml-auto">
						<button className="btn" type="button" onClick={() => setIsModalOpen(false)}>
							Cancel
						</button>
						<button className="btn-primary">Next</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

const Item = ({ item }) => {
	const { setFieldValue, values } = useFormikContext();

	return (
		<div className="border border-lightGray rounded-lg flex flex-col p-[1.2rem]">
			<p className="text-lightText text-[1.4rem] font-medium mb-[3px] capitalize">{item.option_name}</p>
			<p className="text-lightText text-[1.2rem] font-medium tracking-tighter">{renderCurrency(item.price)}</p>
			<QuantityInput
				value={values.array[item.option_name]?.quantity || 0}
				onChange={(ev) => {
					const clone = {
						...values.array,
						...{
							[item.option_name]: {
								quantity: ev,
								price: item.price,
								product_id: item.product_id,
								product_option_id: item.option_id,
								expressPrice: item.expressPrice,
								cost_price: item.cost_price,
								pieces: item.pieces,
							},
						},
					};
					if (ev === 0) delete clone[item.option_name];
					setFieldValue("array", clone);
				}}
			/>
		</div>
	);
};

export default OrderItemsSelector;
