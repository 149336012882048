import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getCharges } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { EditIcon } from "src/assets/svg";
import { CustomDataTable, Modal } from "src/components";
import AddCharge from "src/modals/settings/AddCharge";

const Charges = ({ openModal, setOpenModal }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedpatterndetails, setselectedpatterndetails] = useState(null);

	const { data, refetch } = useQuery({
		queryKey: ["charges"],
		queryFn: getCharges,
		suspense: true,
	});

	const columns = [
		{
			name: "Charge Name",
			selector: (row) => row?.name,
			compact: true,
		},
		{
			name: "Charge Amount",
			selector: (row) => renderCurrency(row?.value),
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setselectedpatterndetails(row);
							setIsEditModalOpen(true);
						}}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	return (
		<>
			<CustomDataTable columns={columns} data={data.data} />
			<Modal isOpen={openModal} setIsOpen={setOpenModal} title="Add Charge">
				<AddCharge setOpenModal={setOpenModal} refetch={refetch} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Charge">
				<AddCharge setOpenModal={setIsEditModalOpen} refetch={refetch} selectedCharge={selectedpatterndetails} />
			</Modal>
		</>
	);
};

export default Charges;
