import { getFromStorage, renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import axios from "axios";
import fileDownload from "js-file-download";
import { format, parse } from "date-fns";

const authApi = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Credentials": true,
	},
	withCredentials: true,
});

authApi.interceptors.response.use(
	(response) => response.data,
	(error) => {
		if (error.response.status === 401) {
			renderErrorMessage(error.response.data.message).then(() => {
				localStorage.clear();
				window.location.reload();
			});
		} else if (error.response.status === 503) {
			renderSuccessMessage(error.response.data.message);
		} else {
			renderErrorMessage(error.response.data.message || error.response.data.error);
			throw new Error(error.response.data.message, {
				cause: error.response.status,
			});
		}
	}
);

export const signin = (data) => {
	return authApi.post(`/auth/login`, data);
};

//
//
//

const api = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Credentials": true,
	},
	withCredentials: true,
});

api.interceptors.request.use((config) => {
	config.headers["Authorization"] = `Bearer ${getFromStorage("token")}`;
	return config;
}, null);

api.interceptors.response.use(
	(response) => response.data,
	(error) => {
		if (error.response.status === 401) {
			renderErrorMessage(error.response.data.message).then(() => {
				localStorage.clear();
				window.location.reload();
			});
		} else if (error.response.status === 503) {
			renderSuccessMessage(error.response.data.message);
		} else {
			renderErrorMessage(error.response.data.message || error.response.data.error);
			throw new Error(error.response.data.message, {
				cause: error.response.status,
			});
		}
	}
);

//? Orders endpoints
export const getOrders = (params) => api.get("all_order", { params });

export const getOrderInfo = (params) => api.get("order", { params });

export const getProducts = () => {
	return api.get("/products");
};
export const getAllProducts = () => {
	return api.get("/products/all");
};

export const createOrder = (data) => api.post(`/order/create`, data);

export const editOrder = ({ id, ...data }) => api.post(`/order/edit/${id}`, data);

export const editOrderRack = ({ id, ...data }) => api.post(`/order/rack/add/${id}`, data);

export const createPreOrder = (data) => api.post(`/order/preorder`, data);

export const getOrderById = (id) => api.get(`/get-order/${id}`);

//? Customer endpoints

export const getallcustomers = (params) => {
	return api.get(`/customer/all`, { params });
};
// Get Single Customer Order Details
export const getcustomerOrder = (id, status) => {
	return api.get(`order/customer?customerId=${id}&status=${status}`);
};

// Get Single Customer Order Details
export const getSinglecustomerUnpaid = (id) => {
	return api.get(`order/customer?customerId=${id}`);
};

export const addcustomer = (data) => {
	return api.post(`/customer/create`, data);
};

export const exportCustomer = async ({ startDate, endDate }) => {
	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());

	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	return api.get("/customer/export", { params: { startDate, endDate }, responseType: "blob" }).then(function (response) {
		const url = window.URL.createObjectURL(new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `Customer Export ${formattedStartDate} - ${formattedEndDate}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};

export const exportWalletFunding = async (params) =>
	api.get("/wallet-funding", { params, responseType: "blob" }).then(function (response) {
		const url = window.URL.createObjectURL(new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `Wallet Funding Report.xlsx`);
		document.body.appendChild(link);
		link.click();
	});

export const exportOrderPayments = async (params) =>
	api.get("/order-finance", { params, responseType: "blob" }).then(function (response) {
		const url = window.URL.createObjectURL(new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `Order Payment Report.xlsx`);
		document.body.appendChild(link);
		link.click();
	});

export const findCustomer = (search_text) => {
	return api.post(`/customer/find`, { search_text });
};

export const findOneCustomer = (uuid) => {
	return api.get(`/customer/view/${uuid}`);
};

export const editcustomer = ({ id, ...data }) => {
	return api.post(`/customer/edit/${id}`, data);
};

export const fundCustomerWallet = ({ id, ...data }) => {
	return api.post(`/customer/wallet/${id}`, data);
};

export const mergeCustomers = (data) => {
	return api.post(`/customer/merge`, data);
};

//Create Order Option
export const EditOrderOption = (data) => {
	return api.patch("/order_option", data);
};

//Get Order Option
export const getOrderOption = () => {
	return api.get("/order_option");
};

// Orders Per Hour for Dasboard
export const OrdersPerHour = (params) => {
	return api.get("/reportsPerHour", { params });
};

//? Staff endpoints

// get all staff details
export const getAllStaff = (params) => {
	return api.get("/users", { params });
};

// get one staff detail
export const getStaffDetail = (uuid) => {
	return api.get(`/users/details/${uuid}`);
};

// create staff
export const createStaff = (data) => {
	return api.post("user/create", data);
};

// edit staff details
export const editStaff = ({ uuid, ...data }) => {
	return api.post(`/user/edit/${uuid}`, data);
};

// delete staff
export const deleteStaff = (uuid) => {
	return api.delete(`/user/delete/${uuid}`);
};

// switch user
export const switchUser = (data) => {
	return api.post(`/switch-user`, data);
};

//? route endpoints

// create route
export const createRoute = (data) => {
	return api.post("route/create", data);
};

// edit route
export const editRoute = ({ id, ...data }) => {
	return api.post(`route/edit/${id}`, data);
};

// delete Route
export const deleteRoute = (id) => {
	return api.delete(`route/delete/${id}`);
};

// get all routes
export const getAllRoutes = () => {
	return api.get("/route");
};

// edit damages
export const editDamages = ({ id, ...data }) => {
	return api.patch(`item/damages/${id}`, data);
};
// create damages
export const createDamages = (data) => {
	return api.post("item/damages", data);
};

// delete Damages
export const deleteDamage = (id) => {
	return api.delete(`item/damages/${id}`);
};

// get all damages
export const getAllDamages = () => {
	return api.get("/item/damages");
};

//getPattern
export const getAllPattern = () => {
	return api.get("/item/pattern");
};

// edit Pattern
export const editPattern = ({ id, ...data }) => {
	return api.post(`item/pattern/${id}`, data, { headers: { "Content-Type": "multipart/form-data" } });
};
// create Pattern
export const createPattern = (data) => {
	return api.post("item/pattern", data, { headers: { "Content-Type": "multipart/form-data" } });
};

//DeletePattern
export const DeletePattern = (id) => {
	return api.delete(`item/pattern/${id}`);
};

//? DEPARTMENT endpoint
// get all departments
export const getAllDepartments = () => {
	return api.get("/departments");
};

// delete Department
export const deleteDepartment = (id) => {
	return api.delete(`department/delete/${id}`);
};

// edit department
export const editDepartment = ({ id, ...data }) => {
	return api.post(`department/edit/${id}`, data);
};

// create department
export const createDepartment = (data) => {
	return api.post("department/create", data);
};

//? Role endpoint
// get all roles
export const getAllRoles = () => {
	return api.get("/roles");
};

// create role
export const createRole = (data) => {
	return api.post("/roles", data);
};

// edit role
export const editRole = ({ id, ...data }) => {
	return api.patch(`/roles/${id}`, data);
};

// delete role
export const deleteRole = (id) => {
	return api.delete(`/roles/${id}`);
};

//Clock in
export const staffClockIn = (data) => {
	return api.post(`/user/clockin`, data);
};

//Clock in
export const staffClockOut = (data) => {
	return api.post(`user/clockout`, data);
};

// Check if staff has clocked in/out
export const confirmClockin = (uuid) => {
	return api.get(`user/confirm_clockin/${uuid}`);
};

//? Settings endpoint
//---LOCATION---//
//---LOCATION---//

export const getAllLocation = () => {
	return api.get("/locations");
};

export const editLocation = ({ id, ...data }) => {
	return api.post(`/location/edit/${id}`, data);
};

export const addLocation = (data) => {
	return api.post(`/location/create`, data);
};

export const deleteLocation = (id) => {
	return api.delete(`/location/delete/${id}`);
};

// get Dasboard Analytics
export const getDashboardAnalytics = (params) => {
	return api.get("order/dashboard-clone", { params });
};

// get Metrics Overview
export const getMetricsOverview = (params) => {
	return api.get("overview-numbers", { params });
};

// get Already Due and Due Tommorow Orders
export const getDueOrders = () => {
	return api.get("overview?limit%20=6");
};

// get Revenue
export const getRevenue = (params) => {
	return api.get("revenue", { params });
};

//get getMetricsOrders
export const getMetricsOrders = (params) => {
	return api.get("order-metric", { params });
};

//get getMetricsOrders for customers
export const getCustomerOrderMetrics = () => {
	return api.get("customer-metric?limit=10");
};

//Metrics for new customers
export const getNewCustomerMetrics = () => {
	return api.get("customer-details?limit=10&isRecentOrder=true");
};

//Metrics for customers with no recent orders
export const getNewCustomerMetricsWithNoRecentOrders = () => {
	return api.get("customer-details?limit=10");
};

//---PRODUCTS---//
//---PRODUCTS---//

export const getProductGroups = () => api.get("/product_groups");

export const createProductGroup = (data) => api.post("/product_group/add", data);

export const editProductGroup = ({ id, ...data }) => api.post(`/product_group/edit/${id}`, data);

export const deleteProductGroup = (id) => api.delete(`/product_group/${id}`);

export const getProductGroupById = (id) => api.get(`/product_group/single/${id}`);

export const createProduct = (data) =>
	api.post("/product/create", data, {
		headers: { "Content-Type": "multipart/form-data" },
	});

export const editProduct = ({ id, ...data }) =>
	api.post(`/product/edit/${id}`, data, {
		headers: { "Content-Type": "multipart/form-data" },
	});

export const deleteProduct = (id) => api.delete(`/product/delete/${id}`);

export const createProductOption = (data) => api.post("/product_option/add", data);

export const editProductOption = ({ id, ...data }) => api.post(`/product_option/edit/${id}`, data);

export const deleteProductOption = (id) => api.delete(`/product_option/delete/${id}`);

//? Track endpoint
export const trackItem = (id) => api.get(`/track/${id}`);

export const trackItemHistory = (id) => api.get(`/item/getHistory/${id}`);

//? Report endpoint

export const getAllReports = ({ id, storeId, itemId, startDate, endDate, departmentId, staffId }) => {
	switch (id) {
		case 1:
			return api.get("/report/locations", {
				params: {
					locationId: storeId,
					productId: itemId,
					startDate,
					endDate,
				},
			});
		case 2:
			return api.get("/report/departments", {
				params: {
					departmentId,
					startDate,
					endDate,
				},
			});
		case 4:
			return api.get("/report/orders", {
				params: {
					date: startDate,
				},
			});
		case 5:
			return api.get("/report/orders/department", {
				params: {
					date: startDate,
					departmentId,
				},
			});
		case 6:
			return api.get("/report/rewash", {
				params: {
					date: startDate,
				},
			});
		case 8:
			return api.get("/report/rejected", {
				params: {
					date: startDate,
				},
			});
		case 9:
			return api.get("/report/individual", {
				params: {
					staffId,
					startDate,
					endDate,
				},
			});
		default:
			// Handle unknown id case if needed
			return Promise.reject("Invalid id");
	}
};

//---ItemSettings---//
//---ItemSettings---//

export const addItemSettings = (data) => {
	return api.post(`/item/settings`, data);
};

export const getItemSettings = () => {
	return api.post(`/item/settings?get=true`);
};

export const addSingleColorOrBrand = (data) => api.patch("/item/settings/color-brand?id=1", data);

export const generateOutboundScanCodes = ({ tagId, orderId, qr }) =>
	api.get(`/item/generate-code/${tagId}/${orderId}`, {
		params: {
			...(qr ? { qrcode: true } : { barcode: true }),
		},
	});

export const generateBatchOutboundScanCodes = ({ order_id, qr }) =>
	api.get(`/item/generatecodeBulk`, {
		params: {
			...(qr ? { qrcode: true } : { barcode: true }),
			order_id,
		},
	});

export const getMetricsPeriod = ({ startDate, endDate, period }) => {
	return api.get(`/metrics?start_date_period_2=${startDate}&end_date_period_2=${endDate}&${period}=true'`);
};

//? Delivery Option
export const createDeliveryOption = (data) => api.post("/delivery", data);

export const getDeliveryOptions = () => api.get("/delivery");

export const editDeliveryOption = ({ id, ...data }) => api.patch(`/delivery/${id}`, data);

export const deleteDeliveryOption = (id) => api.delete(`/delivery/${id}`);

// get Unpaid Analytics
export const getUnpaidMetrics = () => {
	return api.get("/unpaid");
};

// get Cleaning Metrics
export const getCleaningMetrics = ({ startDate, endDate }) => {
	return api.get(`/cleaning?startDatePeriod=${startDate}&endDatePeriod=${endDate}`);
};

export const getUserClockInOuts = (id) => api.get(`user/clockin_history/${id}`);

export const createInvoice = (data) => api.post("/order/invoice", data);

export const getItemInfo = (data) => api.get(`item/info/${data}`);

export const getOrder = (data) => api.get(`/order-by-id/${data}`);

export const getInvoice = (status = 0) => api.get(`/order/invoice`, { params: status === "all" ? {} : { status } });

export const exportTracker = (id) =>
	api.get(`/item/report/${id}`, { responseType: "blob" }).then((res) => {
		fileDownload(res.data, "file.csv");
	});

export const scanOrder = (data) => api.post("/update-scan", data);

export const generateMovementSheet = (data) => api.post("/order/generate-movement-list", data);

export const getMovementSheet = (params) => api.get("/order/get-movement-lists", { params });

export const getSingleMovementSheet = (params) => api.get("/order/get-single-list", { params });

export const deleteOrder = ({ id, ...data }) => api.delete(`/order/delete/${id}`, { data });

export const editOrderItem = (data) => api.post("/order/edit-item", data);

export const setAsCompleted = (data) => api.post("/order/make-payment", data);

export const getDiscountType = (data) => api.get("/discount-types", data);

export const createDiscountType = (data) => api.post("/discount-types", data);

export const editDiscountType = ({ id, ...data }) => api.patch("/discount-types", data, { params: { id } });

export const getCharges = (data) => api.get("/charge", data);

export const createCharge = (data) => api.post("/charge", data);

export const editCharge = ({ id, ...data }) => api.patch("/charge", data, { params: { id } });

export const deleteCustomer = (id) => api.delete(`/customer/delete/${id}`);
