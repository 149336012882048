import React, { Suspense, useEffect, useState } from "react";
import { useOrderContext } from "./context";
import AsyncSelect from "react-select/async";
import { AddIcon } from "src/assets/svg";
import OrderItem from "src/components/orders/OrderItem";
import TabOption from "src/components/orders/TabOption";
import { renderCurrency, renderSuccessMessage } from "src/assets/functions";
import { Loader, Modal } from "src/components";
import { AddCustomer } from "src/modals/customers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createPreOrder, editOrder, findCustomer, getDeliveryOptions, getDiscountType, getOrder, getProducts } from "src/api";
import { Payment } from "src/modals/orders";
import { QuantityInput } from "src/components/orders/QuantityInput";
import * as Yup from "yup";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { useLocation, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { DebounceInput } from "react-debounce-input";

const quickDropSchema = Yup.object().shape({
	customerId: Yup.string().required("Please select a customer for the order"),
	store_id: Yup.string().required("Store ID is required for the order"),
	staffId: Yup.string().required("Staff ID is required for the order"),
	items_count: Yup.number().min(1, "Minumun number of items for quick drop is 1").required("Item count is required for the order"),
	isExpress: Yup.boolean(),
	paymentType: Yup.string().required("A method of payment is required"),
	deliveryId: Yup.string().required("Please select a delivery option"),
	dateTimeOut: Yup.string().required("A completion date is required"),
});
const normalOrderSchema = Yup.object().shape({
	customer_id: Yup.string().required("Please select a customer for the order"),
	store_id: Yup.string().required("Store ID is required for the order"),
	staffId: Yup.string().required("Staff ID is required for the order"),
	total_amount: Yup.number().min(0),
	note: Yup.string(),
	discount: Yup.number().min(0),
	isExpress: Yup.boolean(),
	is_paid: Yup.boolean(),
	order_items: Yup.array().min(1, "There must be at least one item in the order"),
	paymentType: Yup.string().required("A method of payment is required"),
	deliveryId: Yup.string().required("Please select a delivery option"),
	dateTimeOut: Yup.string().required("A completion date is required"),
});

const filterOptions = async (inputValue) => {
	if (!inputValue) return;

	const data = (await findCustomer(inputValue)).data.map((person) => ({
		value: person.id,
		label: person.full_name?.concat(" (", renderCurrency(person?.wallet), ")"),
		discount: person?.discount,
		default_payment: person?.default_payment,
	}));
	return data;
};

const NewOrder = () => {
	const [searchParams] = useSearchParams();

	const [currentTab, setCurrentTab] = useState([]);
	const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
	const [isPaymentOpen, setIsPaymentOpen] = useState(false);

	const { data } = useQuery({
		queryKey: ["products"],
		queryFn: getProducts,
		suspense: true,
	});

	useEffect(() => {
		setCurrentTab(data?.data["product_groups"][0]);
	}, [data]);

	const allProducts = data?.data["product_groups"];

	return (
		<>
			<div className="mt-[1rem] grid gap-3 min-[700px]:grid-cols-3">
				{/* tab items */}
				<div className="col-span-2 bg-white px-[1.75rem] ">
					<div>
						<ul className="second-tabs__tab-list mb-[10px]">
							{allProducts?.map((tab) => (
								<li
									className={`second-tabs__tab ${
										currentTab.group_name === tab.group_name && "second-tabs__tab--selected"
									} capitalize`}
									key={tab.group_name}
									onClick={() => setCurrentTab(tab)}
								>
									{tab.group_name}
								</li>
							))}
						</ul>
						{!!currentTab?.products?.length ? (
							<div className="grid gap-[8px] grid-cols-[repeat(auto-fill,minmax(90px,1fr))] mt-[20px] max-h-[60vh] overflow-y-scroll">
								{currentTab.products?.map((option, index) => (
									<TabOption option={option} key={index} />
								))}
							</div>
						) : (
							<div className="flex justify-center w-full">Nothing here yet</div>
						)}
					</div>
				</div>
				{/* order display */}
				<Tabs
					className="third-tabs max-[700px]:col-span-2"
					selectedTabClassName="third-tabs__tab--selected"
					selectedTabPanelClassName="third-tabs__tab-panel--selected"
					defaultIndex={searchParams.has("quickDrop") ? 1 : 0}
				>
					<TabList className="third-tabs__tab-list">
						<Tab className="third-tabs__tab">New Order</Tab>
						<Tab className="third-tabs__tab">Edit Quick Drop</Tab>
					</TabList>

					{/* New Order Tab */}
					<TabPanel className="third-tabs__tab-panel">
						<NewOrderTab />
					</TabPanel>
					{/* Quick Drop Tab */}
					<TabPanel className="third-tabs__tab-panel">
						<Suspense fallback={<Loader spin />}>
							<QuickDropTab />
						</Suspense>
					</TabPanel>
				</Tabs>
			</div>
			<Modal isOpen={isCustomerModalOpen} setIsOpen={setIsCustomerModalOpen} title="Add Customer" size="large">
				<AddCustomer setIsOpen={setIsCustomerModalOpen} />
			</Modal>
			<Modal isOpen={isPaymentOpen} setIsOpen={setIsPaymentOpen} title="Payment" size="xsm">
				<Payment setIsPaymentOpen={setIsPaymentOpen} />
			</Modal>
		</>
	);
};

const NewOrderTab = () => {
	const { user } = useProtectedRoutesContext();
	const { state: routeState } = useLocation();

	const {
		orderItems,
		customerID,
		setCustomerID,
		setIsExpressDelivery,
		isExpressDelivery,
		notes,
		setNotes,
		totalPrice,
		deliveryOption,
		setDeliveryOption,
		items_count,
		setItems_count,
		resetQuickDrop,
		setPaymentMethod,
		paymentType,
		dateTimeOut,
		setDateTimeOut,
		discount,
		setDiscount,
		isPaid,
		paidAmount,
		revenue,
		discountValue,
		setDiscountValue,
		deliveryAmount,
		setDeliveryAmount,
	} = useOrderContext();

	const [isPaymentOpen, setIsPaymentOpen] = useState(false);
	const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
	const [error, setError] = useState("");

	const { data: deliveryOptions } = useQuery({
		queryKey: ["deleivery-options"],
		queryFn: getDeliveryOptions,
		suspense: true,
	});
	const { data: discountTypes } = useQuery({
		queryKey: ["discount-type"],
		queryFn: getDiscountType,
		suspense: true,
	});

	const { mutate, isLoading } = useMutation(createPreOrder, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => resetQuickDrop()),
	});

	const createQuickDrop = async (details) => {
		try {
			setError("");
			const parsedUser = await quickDropSchema.validate(details);
			mutate(parsedUser);
		} catch (error) {
			setError(error.message);
		}
	};

	useEffect(() => {
		if (routeState) {
			setCustomerID(routeState?.id);
			setDiscount(parseFloat(routeState?.discount) || 0);
			setPaymentMethod(routeState?.default_payment ?? "cash");
		}
	}, [routeState, setCustomerID, setDiscount, setPaymentMethod]);

	return (
		<>
			<div className="bg-white px-[1.25rem] py-[1rem] flex flex-col">
				<h2 className="text-primary text-[1.4rem] font-bold">Customer's Order</h2>
				<p className="text-[1.2rem] text-red-500">{error}</p>

				{/* customer select */}
				<div className="flex items-center gap-4 mb-4">
					<AsyncSelect
						isClearable
						placeholder="Enter customer name or ID"
						loadOptions={filterOptions}
						cacheOptions
						defaultOptions
						styles={{
							container: (baseStyles) => ({
								...baseStyles,
								flex: 1,
							}),
							control: (baseStyles) => ({
								...baseStyles,
								border: "2px solid var(--light-gray)",
							}),
							placeholder: (baseStyles) => ({
								...baseStyles,
								fontSize: "1.4rem",
								fontWeight: 500,
							}),
							valueContainer: (baseStyles) => ({
								...baseStyles,
								padding: "4px 8px",
							}),
							singleValue: (baseStyles) => ({
								...baseStyles,
								fontSize: "1.4rem",
								fontWeight: 500,
							}),
						}}
						components={{
							DropdownIndicator: null,
						}}
						defaultValue={
							!!routeState
								? {
										value: routeState?.id,
										label: routeState?.name,
								  }
								: null
						}
						onChange={(value) => {
							setCustomerID(value?.value);
							setDiscount(parseFloat(value?.discount) || 0);
							setPaymentMethod(value?.default_payment ?? "cash");
						}}
					/>
					<button
						className="flex items-center justify-center bg-primary px-[10px] py-[12px] rounded-md"
						onClick={() => setIsCustomerModalOpen(true)}
					>
						<AddIcon className="text-white stroke-white" />
					</button>
				</div>

				{/* delivery options */}
				<div className="grid grid-cols-2 gap-4 mb-4">
					<select
						className="border border-lightGray rounded-md p-[10px] text-[1.3rem] font-medium placeholder:text-[1.3rem] text-lightText flex-1"
						value={`${deliveryOption}-${parseInt(deliveryAmount)}`}
						onChange={(event) => {
							const [option, amount] = event.target.value.split("-");
							setDeliveryOption(option);
							setDeliveryAmount(parseInt(amount));
						}}
					>
						<option value="" disabled>
							Delivery Fees
						</option>
						{deliveryOptions?.data?.map((option) => (
							<option value={`${option.id}-${parseInt(option?.amount)}`} key={option.id}>
								{option.name} ({renderCurrency(option?.amount)})
							</option>
						))}
					</select>
					<select
						className="border border-lightGray rounded-md p-[10px] text-[1.3rem] font-medium placeholder:text-[1.3rem] text-lightText flex-1"
						value={paymentType}
						onChange={(event) => setPaymentMethod(event.target.value)}
					>
						<option value="" disabled>
							Payment Method
						</option>
						<option value="cash">Cash</option>
						<option value="card">Card</option>
						<option value="cheque">Cheque</option>
						<option value="transfer">Transfer</option>
						<option value="wallet">Wallet</option>
					</select>
					<div className="flex items-center gap-2 bg-lightGray rounded-md px-[12px] py-[10px] flex-1">
						<input
							type="checkbox"
							checked={isExpressDelivery}
							id="Express Delivery"
							onChange={(event) => setIsExpressDelivery(event.target.checked)}
						/>
						<label htmlFor="Express Delivery" className="text-darkText text-[1.2rem] font-medium">
							Express Delivery
						</label>
					</div>
					<DatePicker
						dateFormat="dd MMMM yyyy HH:mm"
						showYearDropdown
						showMonthDropdown
						dropdownMode="select"
						name="birthday"
						className="p-[7px] border border-lightGray rounded-md w-full text-[1.3rem] text-lightText font-medium"
						selected={new Date(dateTimeOut)}
						minDate={new Date()}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						timeCaption="time"
						onChange={(date) => setDateTimeOut(format(date, "yyyy-MM-dd HH:mm:ss"))}
					/>
					<select
						className="border border-lightGray rounded-md p-[10px] text-[1.3rem] font-medium placeholder:text-[1.3rem] text-lightText flex-1 col-span-2"
						value={discountValue}
						onChange={(event) => setDiscountValue(parseFloat(event.target.value))}
					>
						<option value="1">No Discount</option>
						{discountTypes?.data?.map((option) => (
							<option value={`${option.percentage}`} key={option.id}>
								{option.type}
							</option>
						))}
					</select>
				</div>

				{/* Quick Drop  */}
				<div className="border border-lightGray px-[16px] py-[8px] bg-[#FBFBFB] rounded-md">
					<div className="flex items-center justify-between px-4">
						<p className="text-[1.3rem] text-darkText font-medium">Quick Drop</p>
						<QuantityInput value={items_count} onChange={(ev) => setItems_count(ev)} editable />
					</div>
				</div>

				{!!items_count ? (
					<>
						<button
							className="mt-10 btn-primary"
							onClick={() =>
								createQuickDrop({
									customerId: customerID,
									store_id: user?.location_id,
									staffId: user?.id,
									items_count,
									isExpress: isExpressDelivery,
									paymentType,
									deliveryId: deliveryOption,
									dateTimeOut,
								})
							}
							disabled={isLoading}
						>
							Create Quick Drop
						</button>
					</>
				) : (
					<>
						{/* item list */}
						<div className="px-2 mt-[15px] max-h-[30vh] overflow-scroll">
							{orderItems?.map((orderItem, index) => (
								<OrderItem orderItem={orderItem} key={index} index={index} />
							))}
						</div>

						{/* Button, notes, length of order */}
						<div className="border borderlightGray px-[16px] py-[8px] bg-[#FBFBFB] rounded-md mt-[20px]">
							<div className="flex items-center justify-between mb-2">
								<p className="text-[1.3rem] text-darkText font-medium">Total Items</p>
								<p className="text-[1.3rem] text-darkText font-medium">
									{orderItems?.reduce((count, currentItem) => count + parseInt(currentItem?.pieces ?? 1), 0)}
								</p>
							</div>
							<div className="flex items-center justify-between mb-2">
								<p className="text-[1.3rem] text-darkText font-medium">Discount</p>
								<p className="text-[1.3rem] text-darkText font-medium">{discount || 0} %</p>
							</div>
							<div className="flex items-center justify-between mb-2">
								<p className="text-[1.3rem] text-darkText font-medium">Delivery</p>
								<p className="text-[1.3rem] text-darkText font-medium">{renderCurrency(deliveryAmount)}</p>
							</div>
							<div className="flex items-center justify-between mb-2">
								<p className="text-[1.3rem] text-darkText font-medium">Total Price</p>
								<p className="text-[1.3rem] text-darkText font-medium">
									{renderCurrency(totalPrice * ((100 - discount) / 100) * discountValue + deliveryAmount)}
								</p>
							</div>
						</div>

						<textarea
							rows="3"
							className="border border-lightGray bg-[#FBFBFB] mt-4 p-[8px] text-[1.3rem] text-lightText"
							placeholder="Order Notes"
							value={notes}
							onChange={(event) => setNotes(event.target.value)}
						>
							Notes
						</textarea>

						<button
							className="bg-primary text-[1.4rem] font-semibold text-white py-[8px] px-[15px] rounded-md mt-4"
							onClick={async () => {
								const data = {
									customer_id: customerID,
									store_id: user?.location_id,
									staffId: user?.id,
									total_amount: parseFloat(totalPrice),
									note: notes,
									discount: parseFloat(discount),
									isExpress: isExpressDelivery,
									is_paid: isPaid,
									paidAmount: isPaid ? parseFloat(totalPrice) : paidAmount,
									order_items: orderItems,
									extra_info: "",
									revenue: parseFloat(revenue),
									paymentType,
									deliveryId: deliveryOption,
									dateTimeOut,
								};
								try {
									setError("");
									await normalOrderSchema.validate(data);
									setIsPaymentOpen(true);
								} catch (error) {
									setError(error.message);
								}
							}}
						>
							Go to Payment
						</button>
					</>
				)}
			</div>
			<Modal isOpen={isCustomerModalOpen} setIsOpen={setIsCustomerModalOpen} title="Add Customer" size="large">
				<AddCustomer setIsOpen={setIsCustomerModalOpen} />
			</Modal>
			<Modal isOpen={isPaymentOpen} setIsOpen={setIsPaymentOpen} title="Payment" size="xsm">
				<Payment setIsPaymentOpen={setIsPaymentOpen} />
			</Modal>
		</>
	);
};

const QuickDropTab = () => {
	const { orderItems, totalPrice, orderID, setOrderID, resetContext, setIsExpressDelivery, discount } = useOrderContext();
	const [searchParams, setSearchParams] = useSearchParams();

	const [error, setError] = useState("");

	const { mutate, isLoading } = useMutation(editOrder, {
		onSuccess: ({ message, data }) =>
			renderSuccessMessage(message).then(() => {
				window.open(`/receipt/${data?.serial_number}`, "_blank");
				resetContext();
				searchParams.delete("quickDrop");
				setSearchParams(searchParams);
			}),
	});
	useQuery({
		queryKey: ["order", orderID],
		queryFn: () => getOrder(orderID),
		suspense: true,
		enabled: !!orderID,
		onSuccess: ({ data }) => setIsExpressDelivery(!!data?.at(0)?.isExpress),
	});

	const addItemsToQuickOrderSchema = Yup.object().shape({
		id: Yup.string().required("Please provide the ID of the quick drop"),
		bill: Yup.number().min(0),
		order_items: Yup.array().min(1, "There must be at least one item in the order"),
	});

	const addToDrop = async (details) => {
		try {
			setError("");
			const parsedUser = await addItemsToQuickOrderSchema.validate(details);
			mutate(parsedUser);
		} catch (error) {
			setError(error.message);
		}
	};

	useEffect(() => {
		if (searchParams.has("quickDrop")) {
			setOrderID(searchParams.get("quickDrop"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="bg-white px-[1.75rem] py-[1rem] flex flex-col">
				<h2 className="text-primary text-[1.4rem] font-bold mb-[15px]">Add Items to Quick Order</h2>

				<DebounceInput
					debounceTimeout={1000}
					type="text"
					className="py-[6px] px-[8px] border-2 border-lightGray rounded-md text-[1.4rem] text-lightText"
					placeholder="Order ID"
					value={orderID}
					onChange={(event) => setOrderID(event.target.value)}
				/>
				{/* item list */}
				<div className="px-2 mt-[30px] max-h-[30vh] overflow-scroll">
					{orderItems?.map((orderItem, index) => (
						<OrderItem orderItem={orderItem} key={index} index={index} />
					))}
				</div>

				{/* Button, notes, length of order */}
				<div className="border borderlightGray px-[16px] py-[8px] bg-[#FBFBFB] rounded-md mt-[70px]">
					<div className="flex items-center justify-between mb-2">
						<p className="text-[1.3rem] text-darkText font-medium">Total Items</p>
						<p className="text-[1.3rem] text-darkText font-medium">
							{orderItems?.reduce((count, currentItem) => count + parseInt(currentItem?.pieces ?? 1), 0)}
						</p>
					</div>
					<div className="flex items-center justify-between mb-2">
						<p className="text-[1.3rem] text-darkText font-medium">Discount</p>
						<p className="text-[1.3rem] text-darkText font-medium">{discount || 0} %</p>
					</div>
					<div className="flex items-center justify-between mb-2">
						<p className="text-[1.3rem] text-darkText font-medium">Total Price</p>
						<p className="text-[1.3rem] text-darkText font-medium">{renderCurrency(totalPrice * ((100 - discount) / 100))}</p>
					</div>
				</div>

				<button
					className="mt-4 btn-primary"
					disabled={isLoading}
					onClick={() =>
						addToDrop({
							id: orderID,
							bill: parseFloat(totalPrice),
							order_items: orderItems?.map(({ chargeObj, ...item }) => item),
							status: "processing",
						})
					}
				>
					Add Items to Quick Order
				</button>
				<p className="text-[1rem] text-red-500">{error}</p>
			</div>
		</>
	);
};

export default NewOrder;
