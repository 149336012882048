import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { deleteStaff, getAllStaff } from "src/api";
import { renderConfirmDialogue, renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { DeleteIcon, EditIcon, PermissionsIcon, SearchIcon, UserIcon } from "src/assets/svg";
import { CustomDataTable, IconInput, Modal } from "src/components";
import { CreateStaff, StaffDetails, StaffPermission } from "src/modals/staff";

const Staff = () => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;

	const [isStaffDetailsModalOpen, setIsStaffDetailsModalOpen] = useState(false);
	const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
	const [isStaffEditModalOpen, setIsStaffEditModalOpen] = useState(false);
	const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
	const [selectedStaff, setSelectedStaff] = useState(null);
	const [search, setSearch] = useState("");

	const columns = [
		{
			name: "Staff Name",
			cell: (row) => <div className="capitalize">{row?.fullName} </div>,
			compact: true,
		},
		{
			name: "Email address",
			selector: (row) => row?.email,
			compact: true,
		},
		{
			name: "Code",
			selector: (row) => row?.staff_code,
			compact: true,
		},
		{
			name: "Department",
			cell: (row) => <div className="capitalize">{row?.department_name} </div>,
			compact: true,
		},
		{
			name: "Role",
			cell: (row) => <div className="capitalize">{row?.user_role} </div>,
			compact: true,
		},
		{
			name: "Location",
			cell: (row) => <div className="capitalize">{row?.locationName} </div>,
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex items-center gap-4 justify-items-center">
					<UserIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setSelectedStaff(row);
							setIsStaffDetailsModalOpen(true);
						}}
					/>
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setSelectedStaff(row);
							setIsStaffEditModalOpen(true);
						}}
					/>
					<PermissionsIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setSelectedStaff(row);
							setIsPermissionsModalOpen(true);
						}}
					/>
					<DeleteIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() =>
							renderConfirmDialogue("Are you sure you want to delete this staff?").then((res) => {
								if (res.isConfirmed) {
									mutate(row?.uuid);
								}
							})
						}
					/>
				</div>
			),
			width: "100px",
			compact: true,
		},
	];

	const { data, refetch: refetchStaff } = useQuery({
		queryKey: ["allStaff", search, page, per_page],
		queryFn: () => getAllStaff({ search, page, per_page }),
		suspense: true,
	});

	const { mutate } = useMutation(deleteStaff, {
		onSuccess: (res) => {
			renderSuccessMessage(res.message);
			refetchStaff();
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<>
			<div className="flex justify-end gap-5 my-10">
				<IconInput
					icon={<SearchIcon />}
					className="w-[250px] "
					placeholder="Staff Name"
					value={search}
					onChange={(event) => setSearch(event.target.value)}
				/>
				<button
					type="button"
					className="btn-primary"
					onClick={() => {
						setIsStaffModalOpen(true);
					}}
				>
					Add Staff
				</button>
			</div>
			<CustomDataTable columns={columns} data={data?.data?.data} paginationTotalRows={data?.data?.total} />

			<Modal isOpen={isStaffModalOpen} setIsOpen={setIsStaffModalOpen} title="Add Staff" size="large">
				<CreateStaff setIsStaffModalOpen={setIsStaffModalOpen} refetch={refetchStaff} />
			</Modal>
			<Modal isOpen={isStaffEditModalOpen} setIsOpen={setIsStaffEditModalOpen} title="Edit Staff" size="large">
				<CreateStaff staff={selectedStaff} setIsStaffModalOpen={setIsStaffEditModalOpen} refetch={refetchStaff} />
			</Modal>
			<Modal isOpen={isStaffDetailsModalOpen} setIsOpen={setIsStaffDetailsModalOpen} title="Staff Details" size="large">
				<StaffDetails selectedStaff={selectedStaff} />
			</Modal>
			<Modal isOpen={isPermissionsModalOpen} setIsOpen={setIsPermissionsModalOpen} title="Staff Permission" size="large">
				<StaffPermission selectedStaff={selectedStaff} setIsPermissionsModalOpen={setIsPermissionsModalOpen} refetch={refetchStaff} />
			</Modal>
		</>
	);
};

export default Staff;
