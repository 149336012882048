import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { getOrderInfo } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { SearchIcon } from "src/assets/svg";
import { CustomDataTable } from "src/components";

const OrderDetails = ({ page = "Processing", selectedOrder }) => {
	const { data } = useQuery({
		queryKey: ["order_info", selectedOrder?.uuid],
		queryFn: () => getOrderInfo({ uuid: selectedOrder?.uuid }),
		suspense: true,
	});

	const columns = [
		{ name: "ID", selector: (row) => row?.tagId, width: "120px", compact: true },
		{
			name: "Item",
			cell: (row) => {
				const extra_info = JSON.parse(row?.extra_info);
				return (
					<div>
						{[extra_info?.color, row?.brand, row?.name, row?.description || "", `(${row.option_name})`, `| ${extra_info?.style}`].join(
							" "
						)}
					</div>
				);
			},
			style: { textTransform: "capitalize" },
			compact: true,
			grow: 1.5,
		},
		{
			name: "Print",
			cell: (row) => (
				<div className="flex items-center gap-3 py-4">
					<Link
						className="bg-lightPrimary text-primary py-[6px] px-[18px] rounded-lg text-[1.2rem] font-medium text-center"
						to={`/print/small/${row?.tagId}/${selectedOrder.id}?qrcode=true`}
						target="_blank"
					>
						Print Outbound Qr Code
					</Link>
					<Link
						className="bg-lightPrimary text-primary py-[6px] px-[10px] rounded-lg text-[1.2rem] font-medium text-center"
						to={`/print/${row?.tagId}/${selectedOrder.id}?qrcode=true`}
						target="_blank"
					>
						Print QR Code
					</Link>

					<Link to={`/track?tagId=${row?.tagId}`} target="_blank">
						<SearchIcon className="cursor-pointer stroke-primary text-primary" />
					</Link>
				</div>
			),
			compact: true,
		},
	];

	const items = data?.data?.data;
	const deleted = data?.data?.deleted;

	return (
		<>
			<div>
				<div className="flex items-center justify-between mb-[3.5rem]">
					<div>
						<h2 className="text-[2.2rem] text-darkText font-medium">Order #{selectedOrder?.serial_number}</h2>
						<h3 className="text-[1.6rem] text-darkText font-medium">Customer: {selectedOrder?.customer?.full_name}</h3>
					</div>
					<Link className="small-btn-primary" to={`/track/${selectedOrder?.serial_number}`} target="_blank">
						Track Order
					</Link>
				</div>
				<div className="grid grid-cols-3 mb-[3.5rem]">
					<div>
						<p className="text-[#5E6C85] text-[1.2rem] font-normal">CREATED:</p>
						<p className="text-[1.6rem] text-darkText font-medium">
							{format(parseISO(selectedOrder?.created_at), "dd/MM/yyyy")}, Station 1
						</p>
					</div>
					<div className="grid">
						<div className="w-fit justify-self-center">
							<p className=" text-[#5E6C85] text-[1.2rem] font-normal">ORDER BILL:</p>
							<p className="text-[1.6rem] text-darkText font-medium">{renderCurrency(selectedOrder?.bill)}</p>
						</div>
					</div>
					<div className="grid">
						<p className="justify-self-end text-[#5E6C85] text-[1.2rem] font-normal w-fit">AMOUNT PAID:</p>
						<p className="justify-self-end text-[1.6rem] text-darkText font-medium w-fit">{renderCurrency(selectedOrder?.paidAmount)}</p>
					</div>
				</div>
				{!!deleted && (
					<div className="mb-14 justify-self-center">
						<p className=" text-[#5E6C85] text-[1.2rem] font-normal">REASON DELETED:</p>
						<p className="text-[1.6rem] text-darkText font-medium">{deleted?.reason}</p>
					</div>
				)}
				<div className="flex items-center justify-between mb-[2rem]">
					<h2 className="text-darkText text-[1.6rem] font-semibold">{page}</h2>
					<div className="flex items-center gap-2">
						<Link className="small-btn-primary" to={`/print-all/small/${selectedOrder.id}?qrcode=true`} target="_blank">
							Print All Outbound Qr Codes
						</Link>
						<Link className="small-btn-primary" to={`/print-all/${selectedOrder.id}?qrcode=true`} target="_blank">
							Print All Qr Codes
						</Link>
					</div>
				</div>
				<CustomDataTable columns={columns} data={items ?? []} />
			</div>
		</>
	);
};

export default OrderDetails;
