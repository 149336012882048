import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { generateOutboundScanCodes, getItemInfo } from "src/api";
import QRCode from "qrcode";
import { format, parse } from "date-fns";

const Print = ({ small }) => {
	const { tagId, orderId } = useParams();
	const [searchParams] = useSearchParams();

	const componentRef = React.useRef(null);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Print Tag",
		removeAfterPrint: true,
	});

	const { data } = useQuery({
		queryKey: ["print", tagId, orderId],
		queryFn: () => generateOutboundScanCodes({ tagId, orderId, qr: searchParams.has("qrcode") }),
		suspense: true,
	});

	const { data: item } = useQuery({
		queryKey: ["item", tagId],
		queryFn: () => getItemInfo(tagId),
		suspense: true,
	});

	return (
		<div className="flex flex-col items-center justify-center min-h-[50vh] gap-3">
			<ComponentToPrint ref={componentRef} data={data} tagId={tagId} item={item} qr={searchParams.has("qrcode")} small={small} />
			<button className=" btn-primary" onClick={handlePrint}>
				Print
			</button>
		</div>
	);
};

const ComponentToPrint = React.forwardRef((props, ref) => {
	const { small } = props;
	const [qr, setQr] = useState("");

	QRCode.toDataURL(props.tagId, { type: "png" }).then((img) => {
		setQr(img);
	});

	if (props.qr) {
		return (
			<div ref={ref} className="flex flex-col items-center text-center w-fit max-w-[350px]">
				<p className="font-semibold text-[1.2rem]">CLEANACE DRYCLEANERS</p>
				<img src={qr} alt="qrcode" className="w-[200px] aspect-square" />
				<PrintInfo tagId={props.tagId} item={props.item} small={small} />
			</div>
		);
	}
	return (
		<div ref={ref} className="flex flex-col items-center text-center w-fit max-w-[350px]">
			<p className="font-semibold text-[1.2rem]">CLEANACE DRYCLEANERS</p>
			<div dangerouslySetInnerHTML={{ __html: props?.data?.barcode }} className="w-[250px] mb-1"></div>
			<PrintInfo tagId={props.tagId} item={props.item} small={small} />
		</div>
	);
});

const PrintInfo = ({ item, tagId, small }) => {
	const extra_info = JSON.parse(item?.data?.item?.extra_info);

	return (
		<>
			<p className="font-bold text-[3rem]">{item?.data?.order?.serial_number}</p>
			<p className="text-[2.8rem] font-bold">{tagId}</p>
			{!small && (
				<>
					<p className="text-[2.4rem] font-semibold">{item?.data?.item?.product_option?.option_name}</p>
					<p className="text-[2.4rem] font-semibold capitalize">{item?.data?.item?.brand}</p>
					<p className="text-[2.4rem] font-semibold capitalize">
						{extra_info?.color} | {extra_info?.style} | {extra_info?.material} | {extra_info?.pattern} | {extra_info?.stains}
					</p>
					<p className="text-[2.4rem] capitalize font-semibold mt-3">{extra_info?.damage}</p>
				</>
			)}

			<p className={`font-semibold text-[2rem] ${small && "mt-3"}`}>{item?.data?.order?.customer?.full_name}</p>
			<p className="font-bold text-[3rem]">{format(parse(item?.data?.order?.dateTimeOut, "yyyy-MM-dd HH:mm:ss", new Date()), "MMM dd")} </p>
			<p className="font-normal text-[1.6rem] mb-[35px]">
				{format(parse(item?.data?.order?.dateTimeOut, "yyyy-MM-dd HH:mm:ss", new Date()), "hh:mm a")}
			</p>
		</>
	);
};

export default Print;
