import { ErrorMessage, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import { useMutation, useQuery } from "@tanstack/react-query";
import { renderSuccessMessage } from "src/assets/functions";
import { addItemSettings, getItemSettings } from "src/api";
const colourStyles = {
	control: (styles) => ({ ...styles, padding: "1rem 0", borderRadius: "1rem" }),
};

const ItemSettings = () => {
	const validationSchema = Yup.object().shape({
		stains: Yup.array().min(1, "Stains are required"),
		styles: Yup.array().min(1, "Styles are required"),
		materials: Yup.array().min(1, "Materials are required"),
		brand: Yup.array().min(1, "Brands are required"),
		color: Yup.array().min(1, "Colors are required"),
		notes: Yup.string().required("Please provide your receipt notes"),
	});

	const { mutate, isLoading } = useMutation(addItemSettings, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
	});

	const { data, refetch } = useQuery({
		queryKey: ["item-Settings"],
		queryFn: () => getItemSettings(),
		suspense: true,
	});

	const itemSettingsData = data.data?.at(0);

	const initialValues = {
		notes: itemSettingsData?.notes,
		stains: itemSettingsData?.stains
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		styles: itemSettingsData?.styles
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		materials: itemSettingsData?.materials
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		brand: Array.from(new Set(itemSettingsData?.brand?.split(",")))
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		color: Array.from(new Set(itemSettingsData?.color?.split(",")))
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const items = { ...values };
				items.stains = items.stains.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1);
				items.styles = items.styles.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1);
				items.materials = items.materials.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1);
				items.color = items.color.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1);
				items.brand = items.brand.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1);
				mutate(items);
			}}
		>
			{({ values, handleSubmit, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="flex flex-col w-[80%] mx-auto py-10 pb-16">
					<div className="flex flex-col gap-20 mb-16">
						<div className="input-select">
							<p className="text-[1.6rem] text-[#47505B ] font-normal mb-5">Brand</p>
							<CreatableSelect
								closeMenuOnSelect={false}
								name="brand"
								value={values.brand}
								onChange={(value) => setFieldValue("brand", value)}
								isMulti
								styles={colourStyles}
							/>
							<ErrorMessage name="brand" component="div" className="text-red-500 text-[1rem]" />
						</div>
						<div className="input-select">
							<p className="text-[1.6rem] text-[#47505B ] font-normal mb-5">Color</p>
							<CreatableSelect
								closeMenuOnSelect={false}
								name="color"
								value={values.color}
								onChange={(value) => setFieldValue("color", value)}
								isMulti
								styles={colourStyles}
							/>
							<ErrorMessage name="color" component="div" className="text-red-500 text-[1rem]" />
						</div>
						<div className="input-select">
							<p className="text-[1.6rem] text-[#47505B ] font-normal mb-5">Stain</p>
							<CreatableSelect
								closeMenuOnSelect={false}
								name="stains"
								value={values.stains}
								onChange={(value) => setFieldValue("stains", value)}
								isMulti
								styles={colourStyles}
							/>
							<ErrorMessage name="stains" component="div" className="text-red-500 text-[1rem]" />
						</div>
						<div className="input-select">
							<p className="text-[1.6rem] text-[#47505B ] font-normal mb-5">Preference</p>
							<CreatableSelect
								closeMenuOnSelect={false}
								name="styles"
								value={values.styles}
								onChange={(value) => setFieldValue("styles", value)}
								isMulti
								styles={colourStyles}
							/>
							<ErrorMessage name="styles" component="div" className="text-red-500 text-[1rem]" />
						</div>
						<div className="input-select">
							<p className="text-[1.6rem] text-[#47505B ] font-normal mb-5">Material</p>
							<CreatableSelect
								closeMenuOnSelect={false}
								name="materials"
								value={values.materials}
								onChange={(value) => setFieldValue("materials", value)}
								isMulti
								styles={colourStyles}
							/>
							<ErrorMessage name="materials" component="div" className="text-red-500 text-[1rem]" />
						</div>
						<div className="input-select">
							<p className="text-[1.6rem] text-[#47505B ] font-normal mb-5">Order Notes</p>
							<textarea
								rows="15"
								className={`border border-[#CCCCCC] rounded-2xl p-[10px] text-[1.3rem] placeholder:text-[1.3rem] text-lightText w-full`}
								name="notes"
								value={values.notes}
								onChange={(event) => setFieldValue("notes", event.target.value)}
							></textarea>
							<ErrorMessage name="notes" component="div" className="text-red-500 text-[1rem]" />
						</div>
					</div>
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button">
							Cancel
						</button>
						<button className="btn-primary" type="submit" disabled={isLoading}>
							Save
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default ItemSettings;
